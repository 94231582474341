<template>
  <el-dialog v-model="specializedShow" title="异常结果列表" width="70%">
    <div>
      <el-form style="width: 100%">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="请输入身份证号">
              <el-input
                placeholder="请输入身份证号"
                v-model="searchData.id_card"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="请选择对比时间">
              <el-date-picker
                v-model="paymentTime"
                type="daterange"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col :span="1.5">
            <el-form-item>
              <el-button type="primary" @click="onSearch(searchData)">
                搜索
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :span="1.5">
            <el-button
              :loading="loading"
              type="success"
              @click="onPayTheFeesexport"
            >
              导出
            </el-button>
          </el-col>
        </el-row>
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="oldStudent"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#f2f2f2' }"
      >
        <el-table-column
          prop="id_card"
          show-overflow-tooltip
          label="身份证号码"
          width="180"
        />
        <el-table-column
          prop="rows"
          label="行次"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          prop="demo"
          label="对比结果"
          show-overflow-tooltip
          width="950"
        />
        <el-table-column
          prop="create_time"
          label="对比时间"
          show-overflow-tooltip
          width="180"
        />
      </el-table>

      <!-- 分页 -->

      <el-row style="margin-top: 20px">
        <el-col>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="searchData.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchData.total"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>

    <!-- <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeBtn">取消 </el-button>
          <el-button type="primary" @click="onOkBtn">确认</el-button>
        </span>
      </template> -->
  </el-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { graduaErrorAPI, graduareErrorAPI } from '@/api/finance'

// 搜索表单
const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 10, //  每页几条数据
  id_card: '', //  身份证号
  timeStart: '',
  timeEnd: ''
})
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
const paymentTime = ref([new Date(''), new Date('')])
// watch(
//   () => paymentTime.value, // 使用 paymentTime.value 来访问 ref 的值
//   (val) => {
//     searchData.value.timeStart = paymentTime.value[0]
//     searchData.value.timeEnd = paymentTime.value[1]
//   },
//   {
//     deep: true
//   }
// )
watch(
  () => paymentTime,
  (val) => {
    if (!val.value) {
      searchData.value.timeStart = ''
      searchData.value.timeEnd = ''
    } else {
      searchData.value.timeStart = val.value[0]
      searchData.value.timeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)
// 搜索
// const paymentTime = ref([new Date(''), new Date('')])
const onSearch = () => {
  // searchData.value.timeStart =paymentTime.value[0]
  // searchData.value.timeEnd = paymentTime.value[1]
  onListOldStudent()
}

/**
 *  表格 S
 */
const oldStudent = ref([])
const onListOldStudent = async () => {
  const { data, total } = await graduaErrorAPI(searchData.value)
  oldStudent.value = data
  searchData.value.total = total // 设置 'total' 属性
}
onListOldStudent()
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await graduareErrorAPI(searchData.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
  loading.value = false
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onListOldStudent()
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onListOldStudent()
}

/**
 *  分页 E
 */

/** 修改 E */
</script>

<style lang="scss" scoped></style>
