<template>
  <div>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="请输入姓名">
            <el-input v-model="search.name" placeholder="请输入姓名">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入身份证号">
            <el-input v-model="search.id_card" placeholder="请输入身份证号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="请输入院系">
            <el-input placeholder="请输入院系" v-model="search.college_name" />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="请输入专业">
            <el-input placeholder="请输入专业" v-model="search.domain_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="请输入层次">
            <el-input placeholder="请输入层次" v-model="search.level" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入缴费项目">
            <el-input v-model="search.payname" placeholder="请输入缴费项目">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="请选择缴费渠道">
            <el-select placeholder="请选择缴费渠道" v-model="search.pay_type">
              <el-option label="全部" :value="''" />
              <el-option label="光大" :value="0" />
              <el-option label="微信" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="请选择创建时间">
            <el-date-picker
              v-model="createTime"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="请选择支付状态">
            <el-select placeholder="请选择支付状态" v-model="search.payState">
              <el-option label="全部" :value="''" />
              <el-option label="未支付" :value="0" />
              <el-option label="已支付" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="请选择支付时间">
            <el-date-picker
              v-model="paymentTime"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="onPaymentList"> 搜索 </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="13">
          <!-- <el-button type="primary" @click="onPaymentList"> 搜索 </el-button> -->

          <el-button
            v-permission="['financeAddFees']"
            type="primary"
            @click="onChangeTheTime"
          >
            添加
          </el-button>

          <el-button type="primary" @click="templateDownload">
            模板下载
          </el-button>

          <el-button type="primary" @click="excelImport">导入</el-button>
          <el-button
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
          <el-button
            v-permission="['financeAddFees']"
            type="primary"
            @click="graduateResults"
            >异常数据</el-button
          >
          <el-button
            v-permission="['financeAddFees']"
            type="primary"
            @click="onGraduabathEdit"
          >
            批量修改
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 列表 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%; margin-top: 20px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column fixed type="index" label="序号" width="60" />
      <el-table-column fixed prop="name" label="姓名" />
      <el-table-column fixed prop="id_card" label="身份证号" width="160" />
      <el-table-column fixed prop="college_name" label="系部" width="120" />
      <el-table-column prop="domain_name" label="专业" width="120" />
      <el-table-column prop="level" label="层次" width="120" />
      <el-table-column prop="grade" label="年级" width="120" />
      <el-table-column prop="class_name" label="班级" width="150" />
      <el-table-column prop="teacher" label="班主任" width="80" />
      <el-table-column prop="year" label="入学年份" width="80" />
      <el-table-column prop="payname" label="缴费项目" width="150" />
      <el-table-column prop="amt" label="缴费金额" width="80" />
      <el-table-column label="缴费渠道" width="80">
        <template v-slot="{ row }">
          {{ row.pay_type ? '微信支付' : '光大银行' }}
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="80">
        <template v-slot="{ row }">
          {{ row.payState ? '已支付' : '未支付' }}
        </template>
      </el-table-column>
      <el-table-column prop="payTime" label="支付时间" width="150" />
      <!-- <el-table-column prop="start_time" label="缴费开始时间" width="150">
        <template v-slot="{ row }">
          {{ formatDate(row.start_time) }}
        </template>
      </el-table-column> -->
      <el-table-column prop="start_time" label="缴费开始时间" width="150" />
      <el-table-column prop="end_time" label="缴费结束时间" width="150" />

      <!-- <el-table-column prop="start_time" label="缴费开始时间" width="150">
        <template v-slot="{ row }">
          {{ formatDate(row.start_time) }}
        </template>
      </el-table-column> -->
      <el-table-column
        fixed="right"
        prop="create_time"
        label="	创建时间"
        width="150"
      />
      <el-table-column fixed="right" label="操作" width="220">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtnA(row)"
            >修改</el-button
          >
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >详情</el-button
          >
          <el-popconfirm
            title="是否删除该缴费?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 修改时间 -->
    <change-the-time
      v-model="timeVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></change-the-time>

    <graduabath-edit
      v-model="bathVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></graduabath-edit>

    <!-- 详情 -->
    <pay-details
      v-model="detailVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></pay-details>
    <graduate-edit
      v-model="oldStudentShow"
      :studentID="studentID"
      :onPaymentList="onPaymentList"
    ></graduate-edit>
    <graduate-strast
      v-model="graduateVisible"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></graduate-strast>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { ref, watch } from 'vue'
import {
  graduatepayListAPI,
  graduatepayDelAPI,
  reportGradupayAPI
} from '@/api/finance'
// import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import changeTheTime from './components/changeTheTime.vue'
import payDetails from './components/payDetails.vue'
import graduateEdit from './components/graduateEdit.vue'
import graduateStrast from './components/graduateStrast.vue'
import graduabathEdit from './components/graduabathEdit.vue'
// const router = useRouter()
/** 搜索 S */
const search = ref({
  page: 1, // 当前页
  limit: 10, // 每页显示条数
  total: 0,
  name: '', // 缴费名称
  payname: '', // 缴费项目
  id_card: '', // 身份证号
  pay_type: '', // 缴费渠道
  payState: ''
})
// 时间
const time = ref([])
watch(
  () => time,
  (val) => {
    console.log(val.value)
    if (!val.value) {
      search.value.start_time = ''
      search.value.end_time = ''
    } else {
      search.value.start_time = val.value[0]
      search.value.end_time = val.value[1]
    }
  },
  {
    deep: true
  }
)

// 支付时间
const paymentTime = ref([])
watch(
  () => paymentTime,
  (val) => {
    if (!val.value) {
      search.value.paytimeStart = ''
      search.value.paytimeEnd = ''
    } else {
      search.value.paytimeStart = val.value[0]
      search.value.paytimeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)

// 支付时间
const createTime = ref([])
watch(
  () => createTime,
  (val) => {
    if (!val.value) {
      search.value.crtimeStart = ''
      search.value.crtimeEnd = ''
    } else {
      search.value.crtimeStart = val.value[0]
      search.value.crtimeEnd = val.value[1]
    }
  },
  {
    deep: true
  }
)
// 定义日期格式化函数
// const formatDate = (dateString) => {
//   if (!dateString) return ''
//   const date = new Date(dateString)
//   const year = date.getFullYear()
//   const month = String(date.getMonth() + 1).padStart(2, '0') // 补0
//   const day = String(date.getDate()).padStart(2, '0') // 补0
//   return `${year}-${month}-${day}`
// }

// Excel 导入
const router = useRouter()
const excelImport = () => {
  router.push('/graduatePay/import')
}

// 模板下载
const templateDownload = () => {
  window.location.href =
    'https://lnjsxy.college.yslts.com/excel/毕业生交费信息导入模板.xls'
  // window.location.href =
  //   'https://lnjsxy.college.yslts.com/excel/graduatepaysxin.xls'
}
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
/** 搜索 E */

const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportGradupayAPI(search.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
  loading.value = false
}

/** 表格 S */
// 列表数据
const tableData = ref([])
const onPaymentList = async () => {
  const { data, total } = await graduatepayListAPI(search.value)
  search.value.total = total
  tableData.value = data
}
onPaymentList()

// 删除
const deleteBtn = async (id) => {
  await graduatepayDelAPI({ id })
  ElMessage.error('删除成功')
  onPaymentList()
}
// 明细
const detailVisible = ref(false)
const onEditBtn = (row) => {
  detailVisible.value = true
  item.value = row
}
// 修改
const studentID = ref({})
const oldStudentShow = ref(false)

const onEditBtnA = ({ id }) => {
  studentID.value = id
  oldStudentShow.value = true
}

// 批量修改
const bathVisible = ref(false)
const onGraduabathEdit = async () => {
  bathVisible.value = true
  item.value = search.value
}
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onPaymentList()
}

const graduateVisible = ref(false)
const cont = ref({})
const graduateResults = (row) => {
  graduateVisible.value = true
  cont.value = row
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onPaymentList()
}
/**
 *  分页 E
 */

/** 修改时间 S */
const timeVisible = ref(false)
const item = ref({})
const onChangeTheTime = (row) => {
  timeVisible.value = true
  item.value = row
}
/** 修改时间 E */
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
