<template>
  <el-dialog v-model="bathVisible" title="批量修改" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="	缴费项目" prop="pay_name">
        <el-input v-model="ruleForm.pay_name" />
      </el-form-item>
      <el-form-item label="缴费金额" prop="amt">
        <el-input type="number" v-model="ruleForm.amt" />
      </el-form-item>
      <el-form-item label="缴费开始时间" prop="start_time">
        <el-date-picker
          v-model="ruleForm.start_time"
          type="datetime"
          placeholder="缴费开始时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
      <el-form-item label="缴费结束时间" prop="end_time">
        <el-date-picker
          v-model="ruleForm.end_time"
          type="datetime"
          placeholder="缴费结束时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
        <el-button @click="resetForm(ruleFormRef)">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { batchEditAPI } from '@/api/finance'
import { ElMessage } from 'element-plus'
import { replaceObj } from '@/utils/auth'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },

  item: {
    type: Object,
    required: true
  },

  onPaymentList: {
    type: Function
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const bathVisible = useVModel(props) // 得到一个响应式的数据，可以直接修改

watch(
  () => props.item,
  (val) => {
    ruleForm.value = replaceObj(ruleForm.value, val)
  },
  {
    deep: true
  }
)

const ruleFormRef = ref(null)
const ruleForm = ref({
  name: '',
  id_card: '',
  pay_type: '',
  payState: '',
  payname: '',
  start_time: '',
  end_time: ''
})

const rules = {}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      // await batchEditAPI(ruleForm.value)
      const resdata = await batchEditAPI(ruleForm.value)
      console.log('222222222222')
      console.log(resdata)
      console.log('33333333333')
      ElMessage.success('批量修改成功 ' + resdata + ' 条')
      bathVisible.value = false
      props.onPaymentList()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  bathVisible.value = false
}
</script>

<style></style>
