<template>
  <el-dialog v-model="timeVisible" title="添加" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="	姓名" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>

      <el-form-item label="身份证号" prop="id_card">
        <el-input v-model="ruleForm.id_card" />
      </el-form-item>

      <el-form-item label="系部" prop="college_name">
        <el-input v-model="ruleForm.college_name" />
      </el-form-item>
      <el-form-item label="专业" prop="domain_name">
        <el-input v-model="ruleForm.domain_name" />
      </el-form-item>
      <el-form-item label="层次" prop="level">
        <el-input v-model="ruleForm.level" />
      </el-form-item>
      <el-form-item label="年级" prop="grade">
        <el-input v-model="ruleForm.grade" />
      </el-form-item>
      <el-form-item label="班级" prop="class_name">
        <el-input v-model="ruleForm.class_name" />
      </el-form-item>
      <el-form-item label="班主任" prop="teacher">
        <el-input v-model="ruleForm.teacher" />
      </el-form-item>
      <el-form-item label="入学年份" prop="year">
        <el-date-picker
          v-model="ruleForm.year"
          type="year"
          placeholder="请选择入学年份"
          format="YYYY"
          value-format="YYYY"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
      <el-form-item label="	缴费项目" prop="payname">
        <el-input v-model="ruleForm.payname" />
      </el-form-item>
      <el-form-item label="缴费金额" prop="amt">
        <el-input type="number" v-model="ruleForm.amt" />
      </el-form-item>
      <el-form-item label="缴费渠道" prop="pay_type">
        <el-select
          placeholder="请选择缴费渠道"
          v-model="ruleForm.pay_type"
          style="width: 500px"
        >
          <el-option label="光大" :value="0" />
          <el-option label="微信" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="缴费开始时间" prop="start_time">
        <el-date-picker
          v-model="ruleForm.start_time"
          type="datetime"
          placeholder="缴费开始时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
      <el-form-item label="缴费结束时间" prop="end_time">
        <el-date-picker
          v-model="ruleForm.end_time"
          type="datetime"
          placeholder="缴费结束时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
        <el-button @click="resetForm(ruleFormRef)">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { addGraduatepayAPI } from '@/api/finance'
import { ElMessage } from 'element-plus'
import { replaceObj } from '@/utils/auth'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },

  item: {
    type: Object,
    required: true
  },

  onPaymentList: {
    type: Function
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const timeVisible = useVModel(props) // 得到一个响应式的数据，可以直接修改

watch(
  () => props.item,
  (val) => {
    ruleForm.value = replaceObj(ruleForm.value, val)
  },
  {
    deep: true
  }
)

const ruleFormRef = ref(null)
const ruleForm = ref({
  name: '',
  payname: '',
  id_card: '',
  pay_type: '',
  college_name: '',
  domain_name: '',
  level: '',
  grade: '',
  class_name: '',
  teacher: '',
  year: '',
  start_time: '',
  end_time: ''
})

const rules = {
  name: [
    {
      required: true,
      message: '请输入缴费名称',
      trigger: 'blur'
    }
  ],
  id_card: [
    {
      required: true,
      message: '请输入身份证号码',
      trigger: 'blur'
    }
  ],
  pay_type: [
    {
      required: true,
      message: '请选择缴费渠道',
      trigger: 'blur'
    }
  ],
  college_name: [
    {
      required: true,
      message: '请输入系部',
      trigger: 'blur'
    }
  ],
  domain_name: [
    {
      required: true,
      message: '请输入专业',
      trigger: 'blur'
    }
  ],
  level: [
    {
      required: true,
      message: '请输入层次',
      trigger: 'blur'
    }
  ],
  grade: [
    {
      required: true,
      message: '请输入年级',
      trigger: 'blur'
    }
  ],
  class_name: [
    {
      required: true,
      message: '请输入班级',
      trigger: 'blur'
    }
  ],
  teacher: [
    {
      required: true,
      message: '请输入班主任',
      trigger: 'blur'
    }
  ],
  payname: [
    {
      required: true,
      message: '请输入缴费项目',
      trigger: 'blur'
    }
  ],
  amt: [
    {
      required: true,
      message: '请输入缴费金额',
      trigger: 'blur'
    }
  ],
  year: [
    {
      required: true,
      message: '请输入入学年份',
      trigger: 'blur'
    }
  ],
  start_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ],
  end_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await addGraduatepayAPI(ruleForm.value)
      ElMessage.success('添加成功')
      timeVisible.value = false
      props.onPaymentList()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  timeVisible.value = false
}
</script>

<style></style>
